$background-black: #1a1a1d;
$neutral-grey: #d5d5d5;
$dark-red: #6f2232;
$secondary-red: #950740;
$highlight-red: #c3073f;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appbody {
  background-color: $neutral-grey;

  height: 100vh;
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.header {
  background-color: $background-black;
  color: white;
  text-align: center;
  height: 50px;
  margin-bottom: 10px;
}

.debug {
  border: 2px solid #c3073f;
}

.content-card {
  margin-bottom: 2px;
  margin-top: 2px;
}

.side-menu {
  background-color: #7f7f7f;
  display: inline-block;
  padding: 16px;
  border: 2px solid black;
  text-align: center;
}
